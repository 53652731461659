













import { Component, Prop } from 'vue-property-decorator'

import { DropdownItemProps } from '../../../../dsl/molecules/Dropdown/DropdownItem'
import { StructureConfigurable } from '../../../../support/mixins'

import { ListMode, ListModeSwitchProps } from './ListModeSwitch.contracts'
import {
  LIST_MODE_SWITCH_COMPONENT_KEY,
  LIST_MODE_SWITCH_DEFAULT_CONFIG
} from './ListModeSwitch.config'

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
@Component<ListModeSwitch>({
  name: 'ListModeSwitch',
  created (): void {
    this.config = this.getComponentConfig(LIST_MODE_SWITCH_COMPONENT_KEY, LIST_MODE_SWITCH_DEFAULT_CONFIG)
  }
})
export class ListModeSwitch extends StructureConfigurable {
  @Prop({ type: String, default: ListMode.Grid })
  public mode!: ListModeSwitchProps['mode']

  public get icon (): Record<ListMode, string> {
    return this.getConfigProperty('icon')
  }

  public get items (): DropdownItemProps[] {
    return Object.values(ListMode).map((mode) => ({
      label: this.$t(`front.shared.listModeSwitch.${mode}`).toString(),
      type: 'item',
      onClick: () => {
        this.$emit('update:mode', mode)
      }
    }))
  }
}

export default ListModeSwitch
