























import { Component, Mixins } from 'vue-property-decorator'
import { RouteName } from '../../../checkout/routes'
import { Price as ProductPrice } from '../../../../contexts'
import { BaseCartMixin, IBaseCart } from '../../../checkout/shared/mixins/base-cart.mixin'
import Price from '../Price/Price.vue'

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
@Component({
  name: 'CartSummaryBar',
  components: { Price }
})
export class CartSummaryBar extends Mixins<IBaseCart>(BaseCartMixin) {
  public get count (): number {
    if (!this.cart) {
      return 0
    }
    return this.cart.items.reduce((acc, item) => acc + item.quantity, 0)
  }

  public get isDisabled (): boolean {
    return !this.cart?.hasItems()
  }

  public get price (): Partial<ProductPrice> | null {
    if (!this.cart) {
      return null
    }

    return {
      finalPrice: this.cart.getSubtotalPrice(),
      finalPriceNet: this.cart.getSubtotalNetPrice()
    }
  }

  public get shouldShow (): boolean {
    return !!this.cart
  }

  public goToCart (): void {
    this.$router.push({ name: `checkout.${RouteName.Cart}` })
  }
}

export default CartSummaryBar
